import React, { useState, useMemo, useEffect, useRef } from "react";
import { LocalCacheService } from "../../../../../../services/local-cache-service";
import Style from "../../../../Kiosk.module.css";
import ItemTable from "./features/item-table";
import SidePanel from "./features/side-panel";
import { queryItemsFromBasicTags } from "../../../../utils/query-items-from-basic-tags/queryItemsFromBasicTags";
import { queryItemsFromParsedTags } from "../../../../utils/query-items-from-parsed-tag/queryItemsFromParsedTags";
import {
  ClearScheduledInterval,
  ScheduledSyncWorkflowInterval
} from "../../../../utils/scheduled-sync-workflow-interval";
import { FilterBar } from "./components/filter-bar/FilterBar";
import TopPanel from "./features/top-panel";
import useKioskConfigContext from "../../../../contexts/kiosk-config-context";
import useKioskStateContext from "../../../../contexts/kiosk-state-context";
import useMountedReader, { READ_STATUS_OPTIONS } from "../../../../../../hooks/use-mounted-reader";
import useKioskSearchParams from "../../../../hooks/use-kiosk-search-params";
import { ScannedTag } from "../../../../../../hooks/use-mounted-reader/data/types";
import { KioskItem } from "../../../../data/types";
import { analyzeItemsForItemTypeReport } from "../../../../utils/item-type-report/itemTypeReportHelpers";

interface MainPageProps {
  handleScannedItems?: (items: Record<string, KioskItem>) => void;
  onClearReportRow: (vid: string) => void;
  onClearReport: () => void;
  onClearItem: (itemTypeId: string, epc: string) => void;
}

export const MainPage = ({
  handleScannedItems = () => {},
  onClearReportRow,
  onClearReport,
  onClearItem
}: MainPageProps) => {
  const { actionId } = useKioskSearchParams();
  const intervalIdRef = useRef<number>();
  const inactivityIntervalIdRef = useRef<NodeJS.Timeout>();
  const abortControllerRef = useRef<AbortController>(new AbortController());

  const [showInactivityModal, setShowInactivityModal] = useState<boolean>(false);

  const {
    additionalQueryAttributes,
    upcNumberOfCharactersToTrim,
    inactivityThresholdInMinutes,
    hasSgtinTags,
    itemClassFilters,
    topPanelAttributes,
    sidePanelAttributes,
    defaultLocationIdentifierFilterBy,
    autoStartScanning,
    edgeApiUrl,
    readerOptions,
    showNewUPCs
  } = useKioskConfigContext();
  const {
    panelValues,
    setPanelValues,
    itemByTag,
    setItemByTag,
    setItemTypeReports,
    searchInput,
    setSearchInput,
    setIsSubmitting,
    availableReaders,
    locationOptions,
    setReadStatus: setStateReadStatus
  } = useKioskStateContext();
  const {
    getReaderTagMap,
    startMountedReader,
    resumeMountedReader,
    pauseMountedReader,
    stopMountedReader,
    readStatus
  } = useMountedReader(panelValues.readerLocation?.detectorSerial, edgeApiUrl, readerOptions);

  useEffect(() => {
    return () => {
      setStateReadStatus(READ_STATUS_OPTIONS.NOT_READING);
      abortControllerRef.current.abort();
      ClearScheduledInterval(intervalIdRef.current);
      if (inactivityIntervalIdRef.current) {
        clearInterval(inactivityIntervalIdRef.current);
      }
    };
  }, []);

  useEffect(() => {
    setStateReadStatus(readStatus);
  }, [readStatus]);

  const isSubmitDisabled = useMemo(() => {
    const unfilledAttributes = [...topPanelAttributes, ...sidePanelAttributes].filter((attribute) => {
      return attribute.isRequired && !panelValues[attribute.id];
    });

    return Object.keys(itemByTag).length === 0 || unfilledAttributes.length > 0;
  }, [sidePanelAttributes, panelValues, itemByTag]);

  const itemByTagRef = useRef(itemByTag);
  itemByTagRef.current = itemByTag;

  const queryItemsFromTags = async (discoveredTagData: ScannedTag[]): Promise<Record<string, KioskItem>> => {
    const { itemType: itemTypeAttributes } = additionalQueryAttributes;

    const existingTags: Record<string, KioskItem> = await queryItemsFromBasicTags(
      discoveredTagData,
      additionalQueryAttributes,
      itemClassFilters
    );

    if (hasSgtinTags) {
      const SGTINTags = discoveredTagData.filter((tag) => {
        return !existingTags[tag.vid] && tag.sgtinInfo?.upc;
      });

      const validSGTINTags = await queryItemsFromParsedTags(
        SGTINTags,
        itemByTagRef.current,
        itemTypeAttributes,
        upcNumberOfCharactersToTrim,
        showNewUPCs
      );

      return {
        ...existingTags,
        ...validSGTINTags
      };
    }

    return existingTags;
  };

  // get default location by identifier filter
  const defaultLocation = useMemo(() => {
    if (defaultLocationIdentifierFilterBy && locationOptions.length) {
      const [firstLocationMatched = {}] = locationOptions.filter(({ identifier: locationIdentifier }) => {
        return locationIdentifier?.includes(defaultLocationIdentifierFilterBy);
      });
      return firstLocationMatched;
    }
    return {};
  }, [defaultLocationIdentifierFilterBy, locationOptions]);

  useEffect(() => {
    if (actionId) {
      const newPanelValues = { ...panelValues };

      if (availableReaders.length === 1) {
        const newReader = availableReaders[0];
        newPanelValues.readerLocation = newReader;
      }

      if (defaultLocation && Object.keys(defaultLocation).length) {
        newPanelValues.location = defaultLocation;
      }

      const cachedLocation = LocalCacheService.getCheckOutTableSelectedLocation();
      if (cachedLocation && Object.keys(cachedLocation).length > 0) {
        newPanelValues.location = cachedLocation;
      }

      setPanelValues(newPanelValues);
    } else {
      setPanelValues({});
    }
  }, [actionId, availableReaders, defaultLocation]);

  const onReadStatusUpdate = async (
    currentReadStatus: typeof READ_STATUS_OPTIONS[keyof typeof READ_STATUS_OPTIONS],
    defaultDetectorSerial?: string
  ) => {
    const detectorSerial = defaultDetectorSerial || panelValues.readerLocation.detectorSerial;

    if (!detectorSerial) {
      return;
    }

    const statusOperationMap = {
      [READ_STATUS_OPTIONS.NOT_READING.id]: startMountedReader,
      [READ_STATUS_OPTIONS.PAUSED.id]: resumeMountedReader,
      [READ_STATUS_OPTIONS.IN_PROGRESS.id]: pauseMountedReader,
      [READ_STATUS_OPTIONS.STOPPING.id]: stopMountedReader
    };

    if (!statusOperationMap[currentReadStatus.id]) {
      return;
    }

    abortControllerRef.current.abort();
    abortControllerRef.current = new AbortController();

    const operation = statusOperationMap[currentReadStatus.id];

    try {
      await operation(abortControllerRef.current.signal);
    } catch (e) {
      if ((e as Error).name === "AbortError") {
        return;
      }

      setStateReadStatus(READ_STATUS_OPTIONS.NOT_READING);
    }
  };

  useEffect(() => {
    if (!panelValues?.readerLocation?.detectorSerial) {
      return;
    }

    if (readStatus.id === READ_STATUS_OPTIONS.IN_PROGRESS.id) {
      startWorkflow();
    } else if (autoStartScanning) {
      onReadStatusUpdate(READ_STATUS_OPTIONS.NOT_READING);
    } else {
      ClearScheduledInterval(intervalIdRef.current);
      intervalIdRef.current = undefined;
    }
  }, [panelValues, autoStartScanning, readStatus]);

  useEffect(() => {
    if (showInactivityModal) {
      ClearScheduledInterval(intervalIdRef.current);
      intervalIdRef.current = undefined;
      onReadStatusUpdate(READ_STATUS_OPTIONS.STOPPING);
    }
  }, [showInactivityModal]);

  const startWorkflow = async () => {
    ClearScheduledInterval(intervalIdRef.current);
    intervalIdRef.current = ScheduledSyncWorkflowInterval(async () => {
      const alreadyQueriedTags = { ...itemByTagRef.current };
      const allTags = getReaderTagMap(true);

      const discoveredTags = Object.values(allTags).filter((tagObject) => {
        return !alreadyQueriedTags[tagObject.vid];
      });

      const newItemByTag = await queryItemsFromTags(discoveredTags);

      if (Object.keys(newItemByTag).length) {
        const newItemByTagMap = {
          ...newItemByTag,
          ...(itemByTagRef?.current || {})
        };
        setItemByTag(newItemByTagMap);
        handleScannedItems(newItemByTag);
      }
    }, 1000);

    if (inactivityThresholdInMinutes > 0) {
      if (inactivityIntervalIdRef.current) {
        clearInterval(inactivityIntervalIdRef.current);
      }
      inactivityIntervalIdRef.current = setInterval(() => {
        if (intervalIdRef.current) {
          setShowInactivityModal(true);
        }
      }, 60000 * inactivityThresholdInMinutes);
    }
  };

  const defaultClearItem = (itemTypeId: string, epc: string) => {
    const newItems = { ...itemByTag };
    delete newItems[epc];
    setItemTypeReports(analyzeItemsForItemTypeReport(Object.values(newItems)));

    const updatedItemByTag = { ...itemByTag };
    delete updatedItemByTag[epc];

    setItemByTag(updatedItemByTag);
  };

  return (
    <>
      <div className={Style.body}>
        <div className={Style.main_content_container}>
          <TopPanel
            readStatus={readStatus}
            onReadStatusUpdate={onReadStatusUpdate}
            onClear={() => {
              setItemByTag({});
              setShowInactivityModal(false);
              onClearReport();
            }}
          />
          <FilterBar
            searchInput={searchInput}
            onFilter={setSearchInput}
          />
          <ItemTable
            onClearRow={onClearReportRow}
            onClearItem={onClearItem || defaultClearItem}
          />
        </div>
        {sidePanelAttributes?.length > 0 && <SidePanel />}
      </div>
      <div className={Style.footer}>
        <button
          type="button"
          className={Style.footer_button}
          disabled={isSubmitDisabled}
          onClick={() => {
            setIsSubmitting(true);
          }}
        >
          Submit
        </button>
      </div>
    </>
  );
};
