import React, { useMemo, useEffect, useState } from "react";
import { camelCase } from "lodash";
import { ItemV2 } from "@xemelgo/x-client";
import { useHistory } from "react-router-dom";
import { ItemAssociationConfigContextProvider, useItemAssociationConfigContext } from "./contexts";
import { useXemelgoClient } from "../../services/xemelgo-service";
import { ReactComponent as TransferIcon } from "../../assets/icons/transfer.svg";
import { DetailsPageTable } from "../details-page";
import { Item } from "../../components/paginated-list-table-with-types/data/types";
import { AddItemAssociationModal } from "./features/add-item-association-modal";
import { RemoveItemAssociationModal } from "./features/remove-item-association-modal";
import Style from "./ItemAssociationFeature.module.css";
import { MultiSelectOption } from "../../components/multi-select-actions-bar";
import { GenericQueryResponse, ItemToRemove } from "./data/types";

const STATUS_MAP = {
  HEALTHY: "healthy",
  INACTIVE: "inactive"
};

const REMOVE = "remove";

type ItemAssociationFeatureProps = {
  itemId: string;
};

type ItemAssociationFeatureDefaultExportProps = {
  solutionId: string;
  itemId: string;
};

const ItemAssociationFeature: React.FC<ItemAssociationFeatureProps> = ({ itemId }) => {
  const xemelgoClient = useXemelgoClient();

  const {
    tableControl: headers,
    statusFlagMap,
    relationshipMap,
    itemQueryFields,
    itemTypeQueryFields
  } = useItemAssociationConfigContext();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [itemAssociationData, setItemAssociationData] = useState<ItemV2 | null>(null);
  const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [selectedItemIdMap, setSelectedItemIdMap] = useState<Record<string, boolean>>({});

  const history = useHistory();

  const calculateStatus = (item: GenericQueryResponse) => {
    const sessions = item!.associatedWithSession as GenericQueryResponse[];
    const status = [];
    if (sessions.length === 0 && statusFlagMap[STATUS_MAP.INACTIVE]) {
      status.push(statusFlagMap[STATUS_MAP.INACTIVE]);
    } else if (statusFlagMap[STATUS_MAP.HEALTHY]) {
      status.push(statusFlagMap[STATUS_MAP.HEALTHY]);
    }

    return status;
  };

  const formattedItemAssociationData = useMemo<Item[]>(() => {
    if (!itemAssociationData) {
      return [];
    }

    const connections = Object.keys(relationshipMap);
    const tempFormattedItemAssociationData: Item[] = [];
    const responseData = itemAssociationData.getResponseData() as GenericQueryResponse;
    connections.forEach((connection: string) => {
      const associatedItems = responseData[connection] as Array<GenericQueryResponse>;
      if (associatedItems?.length) {
        associatedItems.forEach((item) => {
          const sessions = item!.associatedWithSession as GenericQueryResponse[];
          const session = sessions[0] || {};
          const itemTypes = item!.ofType as GenericQueryResponse[];
          const itemType = itemTypes[0] || {};
          const formattedItemTypeProps = {} as Record<string, unknown>;
          const status = calculateStatus(item);
          Object.keys(itemType).forEach((key) => {
            formattedItemTypeProps[camelCase(`itemType_${key}`)] = itemType[key];
          });

          tempFormattedItemAssociationData.push({
            ...session,
            ...formattedItemTypeProps,
            ...item,
            id: item.id,
            connection,
            relationship: relationshipMap[connection]?.label || connection,
            status,
            isChecked: !!selectedItemIdMap[item.id]
          });
        });
      }
    });

    return tempFormattedItemAssociationData;
  }, [itemAssociationData, selectedItemIdMap, relationshipMap]);

  const itemsToRemove = useMemo<ItemToRemove[]>(() => {
    const tempItemsToRemove: ItemToRemove[] = [];
    formattedItemAssociationData.forEach((item: Item) => {
      const selectedItemId = item.id;
      if (selectedItemIdMap[selectedItemId]) {
        const connection = item.connection as string;
        tempItemsToRemove.push({
          mainItemId: itemId,
          selectedItemId,
          connection
        });
      }
    });
    return tempItemsToRemove;
  }, [formattedItemAssociationData, itemId, selectedItemIdMap]);

  const onLoad = async () => {
    setIsLoading(true);
    const connections = Object.keys(relationshipMap);
    const itemClient = xemelgoClient.getItemClient();
    const response =
      (await itemClient.getItemsByIds([itemId], itemQueryFields, itemTypeQueryFields, connections)) || [];
    const item = response[0];
    setItemAssociationData(item);
    setIsLoading(false);
  };

  useEffect(() => {
    onLoad();
  }, []);

  return (
    <div className={Style.table_container}>
      <div className={Style.title_label}>Item Association</div>
      <DetailsPageTable
        exportEnabled
        selectable
        multiSelectActionsBarEnbaled={Object.keys(selectedItemIdMap).length > 0}
        showMultiBarSelectAll
        dataList={formattedItemAssociationData}
        isLoading={isLoading}
        headers={headers}
        headerContainerClassName={formattedItemAssociationData.length > 0 ? Style.table_header : undefined}
        rowClassName={Style.table_row}
        rowContainerClassName={Style.table_item_row}
        getTitleURLCallbackFn={(item: any) => {
          return `${history.location.pathname}?itemId=${item.id}`;
        }}
        additionalButtons={[
          {
            label: "+ Add",
            onClick: () => {
              setShowAddModal(true);
            }
          }
        ]}
        onSelectClick={(ids: string[], isChecked: boolean) => {
          const tempSelectedItemIdMap = { ...selectedItemIdMap };
          ids.forEach((id: string) => {
            if (isChecked) {
              tempSelectedItemIdMap[id] = isChecked;
            } else {
              delete tempSelectedItemIdMap[id];
            }
          });
          setSelectedItemIdMap(tempSelectedItemIdMap);
        }}
        numOfSelectedItem={Object.keys(selectedItemIdMap).length}
        multiSelectOptions={[
          {
            id: REMOVE,
            label: "Remove",
            IconComponent: TransferIcon
          }
        ]}
        onOptionClick={(option: MultiSelectOption) => {
          const { id: optionId } = option;
          if (optionId === REMOVE) {
            setShowRemoveModal(true);
          }
        }}
        onDeselectAllClick={() => {
          const tempSelectedItemIdMap = { ...selectedItemIdMap };
          Object.keys(tempSelectedItemIdMap).forEach((id: string) => {
            delete tempSelectedItemIdMap[id];
          });
          setSelectedItemIdMap(tempSelectedItemIdMap);
        }}
      />
      {showRemoveModal && (
        <RemoveItemAssociationModal
          onClose={() => {
            setShowRemoveModal(false);
          }}
          onSubmit={() => {
            setShowRemoveModal(false);
            setSelectedItemIdMap({});
            onLoad();
          }}
          itemsToRemove={itemsToRemove}
        />
      )}
      {showAddModal && (
        <AddItemAssociationModal
          itemId={itemId}
          onClose={() => {
            setShowAddModal(false);
            onLoad();
          }}
        />
      )}
    </div>
  );
};

export default ({ solutionId, itemId }: ItemAssociationFeatureDefaultExportProps) => {
  return (
    <ItemAssociationConfigContextProvider solutionId={solutionId}>
      <ItemAssociationFeature itemId={itemId} />
    </ItemAssociationConfigContextProvider>
  );
};
