import { TransferOrder } from "@xemelgo/x-client";
import onboardItems from "./utils/onboard-items";
import createTransferOrder from "./utils/create-transfer-order";
import updateTransferOrder from "./utils/update-transfer-order";
import publishDetectorEvents from "./utils/publish-detector-events";
import { XemelgoService } from "../../../../../../../../services/XemelgoService";
import { ACTION_OPTIONS_MAP } from "../../../../../../data/constants";
import publishMultiTransferOrderDetectorEvents from "../../features/picklist-verification/utils/publish-multi-transfer-order-detector-event";
import { PRINT_TIMES } from "../../../../../../../../data/constants";
import { ExistingTransferOrderContainer, NewTransferOrderContainer, TransferOrderStage } from "../../data/types";
import { ItemTypeReport, KioskItem, PrintOptions } from "../../../../../../data/types";
import updateContainer from "./utils/update-container";

type SubmitTransferOrderActionParams = {
  existingTransferOrders: TransferOrder[];
  newTransferOrderIdentifier?: string;
  existingContainer?: ExistingTransferOrderContainer;
  newContainer?: NewTransferOrderContainer;
  stage: TransferOrderStage;
  itemTypeReports: ItemTypeReport[];
  detectorId: string;
  printOptions?: PrintOptions;
  printTime: string;
  printItems: (payload: any) => Promise<void>;
  actionType: string;
  containerTypeIdentifier: string;
  itemsInOtherOrder: KioskItem[];
};

export const submitTransferOrderAction = async (params: SubmitTransferOrderActionParams) => {
  const {
    existingTransferOrders,
    newTransferOrderIdentifier,
    existingContainer,
    newContainer,
    stage,
    itemTypeReports,
    detectorId,
    printOptions,
    printTime,
    printItems,
    actionType,
    containerTypeIdentifier = "Pallet",
    itemsInOtherOrder
  } = params;

  const transferClient = XemelgoService.getClient().getTransferClient();
  const selectedContainer: ExistingTransferOrderContainer | NewTransferOrderContainer | undefined =
    existingContainer || newContainer;

  const removeItemIds = itemsInOtherOrder
    .filter((item) => {
      return item.id !== undefined;
    })
    .map((item) => {
      return item.id!;
    });
  await transferClient.removeItemsFromTransferOrder(removeItemIds, containerTypeIdentifier);

  // Onboard new items and get existing items
  const { itemTypeEntries, containerEntry } = await onboardItems(
    itemTypeReports,
    "Inventory",
    selectedContainer,
    containerTypeIdentifier
  );

  // Attach items to container
  if (selectedContainer && containerEntry) {
    await updateContainer(itemTypeEntries, containerEntry, selectedContainer.vid);
  }

  let transferOrderWithContainer: TransferOrder | undefined;
  if (actionType === ACTION_OPTIONS_MAP.ORDER_CREATION) {
    const completeItemTypeReports = containerEntry ? [containerEntry, ...itemTypeEntries] : itemTypeEntries;
    if (!existingTransferOrders.length && newTransferOrderIdentifier) {
      transferOrderWithContainer = await createTransferOrder(
        newTransferOrderIdentifier,
        completeItemTypeReports,
        "Inventory"
      );
    } else if (existingTransferOrders.length) {
      transferOrderWithContainer = await updateTransferOrder(existingTransferOrders[0], completeItemTypeReports);
    } else {
      throw new Error("No transfer order found to update.");
    }

    await publishDetectorEvents(transferOrderWithContainer, itemTypeEntries, stage.submitStatus, detectorId);
  } else if (ACTION_OPTIONS_MAP.PICKLIST_VERIFICATION) {
    if (selectedContainer && containerEntry) {
      // If using a container there should only be one transfer order. Update to add container and items to transfer order.
      transferOrderWithContainer = await updateTransferOrder(existingTransferOrders[0], [containerEntry]);
    }

    await publishMultiTransferOrderDetectorEvents(detectorId, existingTransferOrders, itemTypeEntries, stage);
  }

  if (transferOrderWithContainer && containerEntry) {
    await publishDetectorEvents(transferOrderWithContainer, [containerEntry], stage.submitStatus, detectorId);
  }

  const transferOrderIdentifiers =
    newTransferOrderIdentifier ||
    existingTransferOrders
      .map((order) => {
        return order.identifier;
      })
      .sort()
      .join(", ");

  // Print container tags
  if (selectedContainer && containerEntry && printOptions?.enabled && printTime === PRINT_TIMES.now.id) {
    const printPayload = {
      containerIdentifier: selectedContainer.identifier,
      containerTypeIdentifier: containerEntry.identifier,
      transferOrderIdentifier: transferOrderIdentifiers,
      rfidTag: selectedContainer.vid
    };
    await printItems([printPayload]);
  }
};
